import React, { memo, useLayoutEffect, useState } from 'react'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { theme } from 'twin.macro'

const labelProps = {
  default: {
    textAnchor: 'middle',
    dy: '0.33em',
  },
  rotated: {
    textAnchor: 'end',
    verticalAnchor: 'middle',
    dy: '-0.9em',
    angle: -90,
    width: 80,
  },
}

function CustomAxisBottom({ scale, top, hasLabels = true, tickValues }) {
  const [shouldRotateLabels, setShouldRotateLabels] = useState(() => false)

  useLayoutEffect(() => {
    const xPadding = 5
    const labelElements = document.querySelectorAll('.visx-axis-bottom text')
    const labelTexts = [...labelElements].map((d) => d.getBoundingClientRect())

    const overlappingLabelElement = labelTexts
      .slice(0, -1)
      .find((label, i) => label.right + xPadding >= labelTexts[i + 1].left)

    if (!!overlappingLabelElement) {
      setShouldRotateLabels(true)
    }
  }, [])

  return (
    <AxisBottom
      hideZero
      hideTicks
      top={top}
      scale={scale}
      stroke={theme`colors.grey.4`}
      tickLabelProps={() => ({
        fill: hasLabels ? theme`colors.grey.2` : theme`colors.transparent`,
        fontSize: 12,
        ...labelProps[shouldRotateLabels ? 'rotated' : 'default'],
      })}
      tickValues={tickValues}
    />
  )
}

function Axes({
  xScale,
  yScale,
  top,
  hasLabels = true,
  tickValues,
  leftTickFormat,
}) {
  return (
    <>
      <AxisLeft
        hideZero
        scale={yScale}
        stroke={theme`colors.transparent`}
        tickLabelProps={() => ({
          fill: theme`colors.grey.2`,
          fontSize: 12,
          textAnchor: 'end',
          dy: '0.33em',
          dx: '-0.6em',
        })}
        tickStroke={theme`colors.grey.5`}
        tickFormat={leftTickFormat}
      />
      <CustomAxisBottom
        key={xScale.domain()[0]}
        top={top}
        scale={xScale}
        tickValues={tickValues}
        hasLabels={hasLabels}
      />
    </>
  )
}

export default memo(Axes)
