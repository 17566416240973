import React from 'react'

import Layout from '../../components/Layout'
import ExploreTimeSeries from '../../components/ExploreTimeSeries'

export default function TimeSeriesPage() {
  return (
    <Layout>
      <ExploreTimeSeries />
    </Layout>
  )
}
