import { useState } from 'react'
import { useMeasure } from 'react-use'
import tw, { theme } from 'twin.macro'

import ChartConfigSection from './ChartConfigSection'
import TimeSeries from './TimeSeries'
import {
  HaulOptions,
  TimeOptions,
  LocationOptions,
} from '../components/CustomOptions'
import useGetRoot from '../connectors/aggregateDataContext/useGetRoot'
import useGetKeyFromDepth from '../connectors/dataSelection/useGetKeyFromDepth'

const chartFilterDefinitions = [
  // TODO demographics: age, gender, employee type, length of employment
  // TODO travel: direct vs layover, airline, frequent flyers
  {
    name: 'Haul',
    key: 'haul',
    cat: 'travel',
    options: <HaulOptions />,
    level: 2,
  },
  {
    name: 'Time',
    key: 'time',
    cat: 'travel',
    options: <TimeOptions />,
    level: 1,
  },
  { name: 'Booking Class', key: 'cls', cat: 'travel', level: 2 },
  {
    name: 'Destination',
    key: 'location',
    cat: 'travel',
    options: <LocationOptions />,
    level: 2,
  },
  { name: 'Reason', key: 'flight_reason', cat: 'travel', level: 2 },
  { name: 'Duration', key: 'duration', cat: 'travel', level: 1 },
  { name: 'Age', key: 'age', cat: 'demographics', level: 2 },
  { name: 'Gender', key: 'gender', cat: 'demographics', level: 2 },
  { name: 'Role', key: 'role', cat: 'demographics', level: 2 },
  { name: 'Manager', key: 'manager', cat: 'demographics', level: 2 },
  { name: 'Org Code', key: 'org_code', cat: 'demographics', level: 2 },
  // { name: 'Start Day', key: 'startDay', cat: 'travel' },
]

export default function ExploreTimeSeries() {
  const [filterDefinitions, setFilterDefinitions] = useState(
    chartFilterDefinitions
  )
  const { sortByLevel, getRoot } = useGetRoot(filterDefinitions)
  const getKeyFromDepth = useGetKeyFromDepth(filterDefinitions)
  const [ref, { width, height }] = useMeasure()

  return (
    <div tw="flex-1 flex flex-col lg:items-center justify-center lg:flex-row">
      <div
        css={[
          `height: calc(100vh - ${theme`spacing.20`});`,
          tw`w-full flex flex-col mx-auto lg:order-last overflow-x-auto`,
        ]}
        ref={ref}
      >
        {width && (
          <TimeSeries
            getRoot={getRoot}
            parentWidth={width}
            parentHeight={height}
            setFilterDefinitions={setFilterDefinitions}
            sortByLevel={sortByLevel}
            getKeyFromDepth={getKeyFromDepth}
          />
        )}
      </div>
      <ChartConfigSection
        filterDefinitions={filterDefinitions}
        disableFilters
        shouldHidePerFte
        tw="mt-4 lg:mt-0"
      />
    </div>
  )
}
