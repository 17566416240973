import React from 'react'
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend'
import 'twin.macro'

import { capitalize } from '../util'

const CIRCLE = 14
const RECT_WIDTH = 16
const RECT_HEIGHT = 12

export default function Legend({ scale, isHorizontal = true, ...rest }) {
  if (isHorizontal) {
    return (
      <LegendOrdinal scale={scale}>
        {(labels) => (
          <div tw="flex justify-center">
            {labels.map((label, i) => (
              <LegendItem key={`legend-${i}`} tw="pl-5">
                <svg width={CIRCLE} height={CIRCLE}>
                  <circle
                    fill={label.value}
                    r={CIRCLE / 2}
                    cx={CIRCLE / 2}
                    cy={CIRCLE / 2}
                  />
                </svg>
                <LegendLabel tw="inline-block pl-2 text-xs font-bold truncate">
                  {capitalize(label.text)}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    )
  }

  return (
    <div
      tw="relative w-auto maxWidth[180px] max-h-36 px-2 py-2 border border-grey-4 bg-white shadow-md rounded-sm overflow-y-auto"
      {...rest}
    >
      <div tw="-mt-0.5">
        {scale.domain().map((label, i) => (
          <div key={`legend-${i}-${label}`} tw="pt-0.5 flex items-center">
            <svg width={RECT_WIDTH} height={RECT_HEIGHT} tw="flex-shrink-0">
              <rect
                fill={scale(label)}
                width={RECT_WIDTH}
                height={RECT_HEIGHT}
              />
            </svg>
            <span tw="pl-2 text-xs truncate">{capitalize(label)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
