import React from 'react'
import tw, { css } from 'twin.macro'

const focusStyles = css`
  &:focus + span {
    ${tw`ring-offset-0 ring-2 ring-blue ring-opacity-20`}
  }
`

export default function Switch({
  selected,
  handleChange,
  name = 'switch',
  ...rest
}) {
  return (
    <label tw="relative inline-block w-10 h-4" {...rest}>
      <input
        type="checkbox"
        id={name}
        name={name}
        onChange={handleChange}
        css={[tw`opacity-0 w-0 h-0`, focusStyles]}
      />
      <span
        css={[
          tw`absolute inset-0 cursor-pointer transition transform duration-300 ease-in-out rounded-full border-2 border-grey-3`,
          tw`before:(absolute -top-1 w-5 h-5 bg-blue rounded-full transition transform duration-300 ease-in-out)`,
          selected ? tw`before:translate-x-5` : tw`before:-translate-x-1`,
        ]}
      />
    </label>
  )
}
