import React from 'react'
import 'twin.macro'

export default function ChartTitle({ name, unit, ...rest }) {
  return (
    <text
      x="10"
      y="-55"
      textAnchor="end"
      transform="rotate(-90)"
      fontSize={16}
      tw="fill-current text-grey-1"
      {...rest}
    >
      {name}
      {unit && ` (${unit})`}
    </text>
  )
}
