import React, { memo } from 'react'
import 'twin.macro'

const TooltipContent = memo(({ indicator, label, subLabel }) => (
  <>
    <div tw="text-xs">{indicator}</div>

    <div tw="mt-3 text-base leading-tight">{label}</div>

    {subLabel && (
      <div tw="mt-1 text-blue font-bold text-sm tracking-wide">{subLabel}</div>
    )}
  </>
))

export default TooltipContent
